.main-container {
    height: 90vh !important;
    width: auto;
    display: flex;
    justify-content: start;
    padding: 2rem;
    gap: 15px;
    font-family: "Roboto", sans-serif !important;
}

.separator {
    border-left: 3px solid #2EC2C7;
    /* height: 100vh; */
    margin: 0 auto;
    width: 1px;
}

.col-gap {
    gap: 1rem !important;
}

.border-bottom {
    border-bottom: 1px solid grey;
}

.show {
     display: block !important;
}

.hide { 
    display: none !important;
}

.color-skyblue {
    color: #2EC2C7 !important;
}

.color-purple {
    color: #9473CC !important;
}

.color-green {
    color: #4DA8C7 !important;
}

.color-tintblue {
    color: #6E8CC9 !important;
}

.color-blue {
    color: #318CE7 !important;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    background-color: (242, 245, 250);
    color: var(--color-gray-7a);
}

.ql-container.ql-snow  {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    height: 200px;
    color: var(--color-gray-7a);
}

.ql-editor .ql-blank::part(data-placeholder) {
    font-style: normal;
    text-transform: capitalize !important;
    font-size: 14px !important;
    color: var(--color-shark);
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    font-family: "Roboto", sans-serif !important;
    font-style: normal;
    font-size: 14px !important;
    color: var(--color-gray-7a);
    letter-spacing: 0;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow::placeholder {
    font-style: normal !important;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.ql-container .ql-snow {
    height: 100px !important;
}

.blueHeader {
    color: blue !important;
    font-weight: bold;
}
.innerHtml ul {
    padding-left: 15px !important;
}

body {
    font-family: "Roboto", sans-serif !important;
}

omni-dropdown.container.footer-spacer {
    min-height: 0px !important;
}