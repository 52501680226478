.modal-card {
    width: 740px !important;
    background: linear-gradient(137deg, rgb(223, 86, 120) 4%, rgb(245, 108, 142));
}

.modal-card-body {
    background: linear-gradient(137deg, rgb(223, 86, 120) 4%, rgb(245, 108, 142));
}

.fill-white {
    fill: white !important;
}