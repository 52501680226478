.container {
    display: flex;
    /* box-shadow: rgba(1, 33, 78, 0.15) 0px 1px 10px -3px, rgba(1, 33, 78, 0.18) 0px 15px 12px -9px; */
    border-radius: 10px;
    gap: 2rem;
}

.column-gap {
    display: flex !important;
    flex-direction: column !important;
    gap: 5px !important;
}

.panel {
    padding: 5px;
    overflow: hidden;
    border-bottom: 1px solid rgb(237, 240, 244);
    background-color: white;
    border-left: none;
    border-right: none;
    border-radius: 5px !important;
    margin: 3em !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1); 
}
.panel-expand {
    height: auto;
}
.panel-collapse {
    height: 3em;
    overflow-y: hidden;
}
.panel-content {
    background-color: #f5f8fb;
    border-radius: 5px;
    height: auto;
    border: 1px solid #edf0f5;
}

.error {
    border: solid 1px red !important;
}
/* 
.default-check {
    fill: white !important;
    border-radius: 5px !important;
    width: 18px;
    height: 18px;
    cursor: pointer !important;
    pointer-events: all !important;
}


.current-no-check {
    border: 1px solid black !important;
}

.current-check {
    background: linear-gradient(332.45deg, #00a1d2 14.82%, #03bbf3 86.93%) !important;
} */